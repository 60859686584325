import { Injectable,isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const API_LIST_URL	=	(isDevMode())?'https://test-shoppinglistpwa.familie-van-vugt.nl/idea/':'https://shoppinglistpwa.familie-van-vugt.nl/idea/';

@Injectable({
  providedIn: 'root'
})
export class IdeaserviceService {
constructor(private http: HttpClient) { }
getIdeas(): Observable<any> {
    return this.http.get(API_LIST_URL + 'list', { responseType: 'text' });
    }
newIdea(idea : any): Observable<any> {
    return this.http.post(API_LIST_URL + 'newidea', idea ,{ responseType: 'text' });
    }
removeIdea(idea : any): Observable<any> {
    return this.http.post(API_LIST_URL + 'removeidea', idea, { responseType: 'text' });
    }

}
