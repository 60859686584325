import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, FormArray } from '@angular/forms';
import { ListService } from '../_services/list.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';


@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {

  item: string[];
  form: UntypedFormGroup;
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: string[];
  constructor(private listService: ListService, private snackBar: MatSnackBar, private fb: UntypedFormBuilder) {
    this.item = ['vla', 'koek'];
  }


  ngOnInit(): void {

    this.form = this.fb.group({
      winkel: new UntypedFormControl(''),
      products: new UntypedFormArray([new UntypedFormControl('')])
    });
  }

  my_handler(ev: any, ix: number, id: any): void {
    console.log('chnage' + ix, id.value);

    this.filteredOptions = this._filter(id.value);


  }

  processResult(result: any) {

    const out = [];
    if (result.length > 0) {
      result.forEach(function (val, index, array) {
        out.push(val.name);
      });
    }
    this.item = out;
  }

  loadList() {
    this.listService.getListOfShop(this.form.value.winkel).subscribe({
      next: (result) => this.processResult(JSON.parse(result)),

    });
    console.log(this.form.value.winkel);

  }
  subscribeToChange() {
    const stopsArray = this.form.get('products') as FormArray;



    stopsArray.valueChanges.subscribe(item => {
      // THIS WILL RETURN THE ENTIRE ARRAY SO YOU WILL NEED TO CHECK FOR THE SPECIFC ITEM YOU WANT WHEN CHANGED
      console.log(item);
      item.findIndex(x => console.log(x));
    }
    );
  }


  private _filter(value: string): string[] {
    console.log('value: ' + value);
    const filterValue = value.toLowerCase();
    console.log(filterValue);
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  submit() {
    console.log(this.form.value);

    console.log(this.allproducts.value); // ['SF', 'NY']
    console.log(this.form.value); // { cities: ['SF', 'NY'] }
    console.log(this.products);
    let postData = this.form.value;
    this.listService.postNewitem(postData)
      .subscribe(response => {
        //this.openSnackBar('Product(en) is/zijn toegevoegd bij ' + postData.winkel,'Sluiten');

      }, error => {
        console.warn(error.responseText)
        console.log({ error })
      })
    this.form = this.fb.group({
      winkel: new UntypedFormControl(''),
      // winkel: this.fb.array(['Aldi','Xenos']),
      products: new UntypedFormArray([new UntypedFormControl('')]),

    });
  }

  get allproducts(): UntypedFormArray {
    return this.form.get("products") as UntypedFormArray;
  }

  products(): UntypedFormArray {
    return this.form.get("products") as UntypedFormArray
  }



  newProduct2(): UntypedFormControl {
    let control = new UntypedFormControl('naamproduct');
    ;
    console.log(this.form);
    return control;
  }

  newProduct(): UntypedFormGroup {
    let group = this.fb.group({
      naamproduct: new UntypedFormControl(''),
    });
    console.log(this.form);
    return group;
  }


  addProduct() {
    this.products().push(new UntypedFormControl());
  }

  removeProduct(i: number) {
    this.products().removeAt(i);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }




}
