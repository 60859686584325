import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { ProductService }  from '../_services/product.service';
import { ShopDto }  from '../profile/shop-dto';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  id: number;
  productname: string;
 
  private sub: any;
  public product: ShopDto;
  constructor(private route: ActivatedRoute, private router: Router, private productService: ProductService) { }

  ngOnInit(): void {

    this.sub = this.route.params.subscribe(params => { 
      
      this.id = +params['productId']; // (+) converts string 'id' to a number
     
      this.productService.getProduct(this.id).subscribe(
        data=>{
          this.product = JSON.parse(data);
          this.productname = this.product.name;
          console.log(this.product);
        },
        err=>{
          console.log('Er is een fout opgetreden');
        }

  );



      // In a real app: dispatch action to load the details here.
   });
  }

  deleteProduct(id) :void
  {
   
    this.productService.deleteProduct(id).subscribe(
      data=>{
        this.router.navigate(["/profile",{'showWinkel':true}]);
        
      },
      err=>{
        console.log('Er is iets fout gegaan');
      }
    );
  }

}
