import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GpsService {
API_KEY = '5b3ce3597851110001cf6248ebb24469170d409b9f607bc8eee7ea97';

  constructor(private httpClient: HttpClient) {}
  
      getDistance(start_lat:any,start_long:any,end_lat: any,end_long:any):  Observable<any> {
        
     //   start_long=  '5.6169714';
    //    start_lat=  '52.3025524';

    //    end_long = '5.61866';
    //    end_lat = '52.29982';
        const link = 'https://api.openrouteservice.org/v2/directions/foot-walking?api_key='+this.API_KEY+'&start='+start_long+','+start_lat+'&end='+end_long+','+end_lat;
        
        return this.httpClient.get(link,{ responseType: 'text' });
      }
      

   
}
