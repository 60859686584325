<div mat-dialog-content>
  <p>Wijzig product</p>
  <mat-form-field>
    <mat-label>product</mat-label>
    <input matInput [(ngModel)]="data.product">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Annuleren</button>
  <button mat-button [mat-dialog-close]="data.product" cdkFocusInitial>Wijzig</button>
</div>