<div class="container">  
    
  <h1> Voeg producten toe </h1>  
    
  <form [formGroup]="form" (ngSubmit)="submit()">  
    <mat-form-field class="example-full-width">
      <mat-label>Winkel</mat-label>
      <input type="text" id="winkel" name="winkel" formControlName="winkel" class="form-control" matInput (blur)="loadList()">
    </mat-form-field>
    <cdk-accordion class="example-accordion">
      <cdk-accordion-item
        #accordionItem="cdkAccordionItem"
        class="example-accordion-item"
        role="button"
        tabindex="0">
        
        <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
           Aantal:
            <span class="example-accordion-item-description">
            Klik hier om te {{ accordionItem.expanded ? 'sluiten' : 'openen' }}
          </span>
        </div>
        <div
          class="example-accordion-item-body"
          role="region"
          [style.display]="accordionItem.expanded ? '' : 'none'"
       >
          <div *ngFor="let product of item">
             <div style="border:0px;width:275px;float:left;padding:2px">{{product}} </div>
                <div style="clear:both"></div>
          </div>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>
   <br><i>
    Aantal producten: {{products().controls.length}}</i>
   
    <table class="table table-bordered" formArrayName="products">  
    
      <tr>  
        <th colspan="2">Producten:</th>  
        <th width="150px"><button type="button" (click)="addProduct()" mat-mini-fab [ngStyle]="{transform: 'scale(0.6)'}" color="primary"><mat-icon>add</mat-icon></button></th>  
      </tr>  
      <tr *ngFor="let product of allproducts.controls; let i=index" >  
       
       
        <td>  
          <mat-form-field class="example-full-width">
            <mat-label>Product item</mat-label>
            <input [formControlName]="i" placeholder="Product" matInput [matAutocomplete]="auto" (keyup)="my_handler($event, i, product)">
          </mat-form-field>
        <!--    Product : 
            <input [formControlName]="i" placeholder="Product" matInput [matAutocomplete]="auto" (keyup)="my_handler($event, i, product)">  --> 
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions " [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>    
        
             
        </td>  
        <td style="padding-top:15px">
          <button (click)="removeProduct(i)" mat-mini-fab [ngStyle]="{transform: 'scale(0.6)'}"  color="primary" >
            <mat-icon>delete</mat-icon>
          </button>  
          
        </td>
       
       
      </tr> 
    
    </table>  
    <br><br><br>
    <div class="example-button-row">
    <button type="submit"  mat-raised-button color="primary" >Voeg de producten toe</button>  
   
  </div>
    <br><br><br>
    <br><br><br>
      
  </form>  

</div>  
