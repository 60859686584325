import { Component, OnInit,isDevMode} from '@angular/core';
import { IdeaDto }  from './idea-dto';
import { IdeaserviceService }  from '../_services/ideaservice.service';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-idea',
  templateUrl: './idea.component.html',
  styleUrls: ['./idea.component.css']
})
export class IdeaComponent implements OnInit {
  ideas?: IdeaDto[];
  form: UntypedFormGroup;
  constructor(private ideaserviceService: IdeaserviceService,private fb: UntypedFormBuilder ) { 
  this.form = this.fb.group({
    ideaname: new UntypedFormControl(''),
    });

  }

  ngOnInit(): void {
  	if(isDevMode()){
	console.log('DEvelopment');
	}
	else
	{
		console.log('Production');
		
	}
  	this.ideaserviceService.getIdeas().subscribe(
  data => {
                this.ideas = JSON.parse(data);
      },
      err => {
              this.ideas = JSON.parse(err.error).message;
        console.log('Erorr!');
      }
      );
  }
  removeItem(i)
  {
  this.ideaserviceService.removeIdea(i).subscribe(
  data => {
                this.ideas = JSON.parse(data);
		//                  this.form = this.fb.group({
		//      website: this.fb.array([])
		//    });

      },
      err => {
              this.ideas = JSON.parse(err.error).message;
        console.log('Erorr!');
      }
      );
      }

      submit()
      {
       let postData= this.form.value;
      this.ideaserviceService.newIdea(postData).subscribe(
      response=> {
      this.ideas = JSON.parse(response);
  this.form = this.fb.group({
    ideaname: new UntypedFormControl(''),
    });
      	console.log(response);
      		
	},
	err => {
		console.log('error');
		}
		);

      }
}
