<h3>Aanmelding</h3>
<form [formGroup]="form" (ngSubmit)="clicked=true; submit(form.value)">
        <div style="border:1px;width:150px;float:left">Naam abonnement</div>
	<div style="border:1px;width:150px;float:left"><input type=text required formControlName="subscription"></div>
        <div style="clear:both"></div>
        <div style="border:1px;width:150px;float:left">Omschrijving abonnement (bv Huize van Vugt)</div>
	<div style="border:1px;width:150px;float:left"><input type=text required formControlName="subscription"></div>
        <div style="clear:both"></div>
        <div style="border:1px;width:150px;float:left">Gewenste gebruikersnaam</div>
        <div style="border:1px;width:150px;float:left"><input type=text required formControlName="username" name="naamproduct"></div>
        <div style="clear:both"></div>
        <div style="border:1px;width:150px;float:left">Email</div>
        <div style="border:1px;width:150px;float:left"><input type=text required formControlName="email"></div>
        <div style="clear:both"></div>
        <div style="border:1px;width:150px;float:left">Gewenst wachtwoord</div>
        <div style="border:1px;width:150px;float:left"><input type=password required formControlName="password" name="winkel"></div>
        <div style="clear:both"></div>
 <button class="btn btn-primary" type="submit" [disabled]="clicked || !form.valid" >Aanmelden</button>
</form>
