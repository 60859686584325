<div id=container>
<div id=menuleft [ngClass]="status ? 'menumax' : 'menumin'">
	<div (click)="status=!status" style="float:right;padding-right:10px">
  <mat-icon style="font-size:40px">more_vert</mat-icon>
	</div>
<div class="clearfix"></div>
	<div [ngClass]="status? 'showmenu':'hidemenu'">
		<div (click)="getWinkels()">Winkels</div>
		<div (click)="getCategorieen()">Categorieen</div>
		<div (click)="getProducten()">Producten</div>

	</div>
</div> 


<div id=content style="float:left">
	 {{ content.length? content.length+' resultaten ':'Geen resultaten'}}
	<div *ngIf="!results" (click)="showForm=!showForm">Maak nieuwe winkel aan in de database</div>
	<div [ngClass]="showForm? 'showForm':'hideForm'">
		<form [formGroup]="form" (ngSubmit)="addShop(form.value)">
		<input type=text formControlName="new_shop" placeholder="Naam winkel">
		<button class="btn btn-primary" type="submit">Voeg toe</button>
		</form>
	</div>


<div class=clearfix></div>
<div id=allWinkels style="float:left">
	<div  *ngFor="let shop of content; let index = index;" >
		
		
		<a [routerLink]="['../'+type,shop.id]"> {{shop.name}}</a><br><br>
		
	
	</div>
</div>
</div>
</div>
<div class="clearfix"></div>
