import { Component } from '@angular/core';
import { GpsService } from '../_services/gps.service';
import { GpsDto } from './gps-dto';
import { interval,Subscription } from 'rxjs';



@Component({
  selector: 'app-gps',
  templateUrl: './gps.component.html',
  styleUrls: ['./gps.component.css']
})

 
export class GpsComponent  {
  mySubscription: Subscription
  constructor(private gpservice: GpsService) { 


    

    const someArray: {key: string, lat: string,long: string}[] = [
      {key: 'Aldi', lat: '59.44444',long:'5.666'},
      {key: 'Kruidvat', lat: '52.29982',long:'5.61866'},
      {key: 'Gamma', lat: '52.29807647147858', long:'5.618669718967602'},
      {key: 'Gelderblom',lat:'52.30296801141909', long:'5.61868582821112'}
      
  ];

  console.log(someArray);
  this.shoplocation = someArray.find(({ key }) => key === this.selectedShop);
 
  setTimeout(()=>{this.getLocation()},2000);
  this.mySubscription= interval(5000).subscribe((x =>{
    this.time = Date.now();
    this.getLocation();
}));
  }

  
  result : GpsDto;
  distance: number;
  message: string;
  shoplocation:any;
  observablevar: any;
  latitude: number;
  longitude: number;
  time:number;
  selectedShop:string = 'Gamma';

   findShop(shop) {
    return shop.key === "Aldi";
  }
   
  getMessage() : string{
    this.message = 'U bent verder dan 500 meter verwijderd van de bestemming ('+this.distance+")"
    if(this.distance < 500)
    {
      this.message = 'U bent in de buurt';
    }
    return this.message;
  }

  setShop(shop:string){
    this.selectedShop = shop;
  }
   getLocation(): any{
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition( (position)=>{
          this.longitude = position.coords.longitude;
          this.latitude = position.coords.latitude;
          //console.log(position); //(longitude, latitude);
          this.gpservice.getDistance(this.latitude,this.longitude,this.shoplocation.lat,this.shoplocation.long).subscribe(
            data=> {
              
              this.result = JSON.parse(data);
              console.log('distance: '+ this.result.features[0].properties.summary.distance);
              this.distance = this.result.features[0].properties.summary.distance;
              
             
            }
          );
         

        });
    } else {
       console.log("No support for geolocation")
    }
  }


}
