import { Injectable,isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'

const API_AUTH_URL	=	(isDevMode())?'https://test-shoppinglistpwa.familie-van-vugt.nl/auth/':'https://shoppinglistpwa.familie-van-vugt.nl/auth/';
@Injectable({
  providedIn: 'root'
  })
export class AuthService {

constructor(private http: HttpClient) { }
register(postData): Observable<any> {
    return this.http.post(API_AUTH_URL + 'register', postData, { responseType: 'text' });
    }
login(postData): Observable<any> {
    return this.http.post(API_AUTH_URL + 'login', postData, { responseType: 'text' });
    }
loginWithPin(postData): Observable<any> {
      return this.http.post(API_AUTH_URL + 'login-with-pin', postData, { responseType: 'text' });
      }

}
