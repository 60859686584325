<h3>Ideeen!</h3>
 <form [formGroup]="form" (ngSubmit)="submit()">
    <table class="table table-bordered">
      <tr>
        <td>
            Idee:
            <input type="text" formControlName="ideaname" class="form-control">
        </td>
      </tr>
    </table>

    <button type="submit" class="btn btn-success">Voeg toe</button>

  </form>
 <div *ngFor="let idea of ideas">
              <div style="border:1px;width:25px;float:left"><input type=checkbox name="items[]" value="{{idea.id}}" ></div>
              <div style="border:1px;width:150px;float:left">{{idea.name}} </div>
        <div style="border:1px;width:100px;float:left"><button (click)="removeItem(idea.id)" class="btn btn-danger">Verwijder</button></div>
        <div style="clear:both"></div>
      </div>

