import { Component } from '@angular/core';
import { TokenStorageService } from './_services/token-storage.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showUsername: string;
  constructor(private tokenservice: TokenStorageService, private router: Router) {
    window.addEventListener("focus", this.play);
  };

  play() {
    if (!this.AppisLoggedIn) { this.router.navigate(['/login']); }

  }
  public static isLoggedIn: boolean = false;
  AppisLoggedIn(): boolean {
    AppComponent.isLoggedIn = false;
    if (this.tokenservice.getToken()) {
      AppComponent.isLoggedIn = true;
      this.showUsername = this.tokenservice.getUser().username;
    }
    return AppComponent.isLoggedIn;
  }
  Signout(): void {
    this.tokenservice.signOut();
    //window.location.reload();
    this.router.navigate(['/login']);
  }

  title = 'shoppinglist';
}
