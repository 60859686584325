import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { WinkelService }  from '../_services/winkel.service';
import { CategoryService }  from '../_services/category.service';
import { ProductService }  from '../_services/product.service';
import { FormBuilder, UntypedFormGroup, FormArray, UntypedFormControl } from '@angular/forms';
import { ShopDto }  from './shop-dto';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
status: boolean = false; 
showForm: boolean = true; 
content: ShopDto[] =[];
type: any;
showWinkel: boolean=false;
results: number;
form: UntypedFormGroup;
constructor(private route: ActivatedRoute, private router: Router
  , private winkelService: WinkelService, private categoryService: CategoryService, private productService: ProductService) { }
ngOnInit(): void {
	this.form = new UntypedFormGroup({
    new_shop: new UntypedFormControl(''),
    
  });
	 console.log(this.content);
	 console.log(this.content.length);
   this.route.params.subscribe(params => {
 
    if(params["showWinkel"])
    {
      this.status = true;
      this.getWinkels();
    }
    if(params["showCategory"])
    {
      this.status = true;
      this.getCategorieen();
    }
    if(params["showProduct"])
    {
      this.status = true;
      this.getProducten();
    }
  });
}
  getWinkels(): void{
  	 this.winkelService.getWinkels().subscribe(
	 data => {
      this.content = JSON.parse(data);
      this.results	=	this.content.length;
      this.type   = 'shop-detail';
	 console.log(this.content);
	 console.log(this.results);
      },
      err => {
              this.content = JSON.parse(err.error).message;
        console.log('Niet gelukt om data op te halen!');
      }
      );
      };

      getCategorieen(): void{
        this.categoryService.getCategories().subscribe(
      data => {
         this.content = JSON.parse(data);
         this.results	=	this.content.length;
         this.type    = 'category-detail';
      console.log(this.content);
      console.log(this.results);
         },
         err => {
                 this.content = JSON.parse(err.error).message;
           console.log('Niet gelukt om data op te halen!');
         }
         );
         };

         getProducten(): void{
          this.productService.getProducts().subscribe(
        data => {
           this.content = JSON.parse(data);
           this.results	=	this.content.length;
           this.type    = 'product-detail';
        console.log(this.content);
        console.log(this.results);
           },
           err => {
                   this.content = JSON.parse(err.error).message;
             console.log('Niet gelukt om data op te halen!');
           }
           );
           };

      addShop(formData)
      {
        this.winkelService.addWinkel(formData).subscribe(
          data => {
             this.content = JSON.parse(data);
      
             },
             err => {
                     this.content = JSON.parse(err.error).message;
               console.log('Niet gelukt om data op te halen!');
             }
             );
      }
}
