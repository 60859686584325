import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { CategoryService }  from '../_services/category.service';
import { ShopDto }  from '../profile/shop-dto';

@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.css']
})
export class CategoryDetailComponent implements OnInit {
  id: number;
  categoryname: string;
 
  private sub: any;
  public category: ShopDto;
  constructor(private route: ActivatedRoute, private router: Router, private categoryService: CategoryService) { }

  ngOnInit(): void {

    this.sub = this.route.params.subscribe(params => { 
      
      this.id = +params['categoryId']; // (+) converts string 'id' to a number
     
      this.categoryService.getCategory(this.id).subscribe(
        data=>{
          this.category = JSON.parse(data);
          this.categoryname = this.category.name;
          console.log(this.category);
        },
        err=>{
          console.log('Er is een fout opgetreden');
        }

  );



      // In a real app: dispatch action to load the details here.
   });
  }
  deleteCategory(id) :void
  {
   
    this.categoryService.deleteCategory(id).subscribe(
      data=>{
        this.router.navigate(["/profile",{'showWinkel':true}]);
        
      },
      err=>{
        console.log('Er is iets fout gegaan');
      }
    );
  }
}
