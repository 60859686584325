import { Injectable,isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'

const API_WINKELS_URL      =       (isDevMode())?'https://test-shoppinglistpwa.familie-van-vugt.nl/shop/':'https://shoppinglistpwa.familie-van-vugt.nl/shop/';

@Injectable({
  providedIn: 'root'
})
export class WinkelService {

constructor(private http: HttpClient) { }
getWinkels(): Observable<any> {
    return this.http.get(API_WINKELS_URL + 'overview', { responseType: 'text' });
    }
    addWinkel(postData): Observable<any> {
      return this.http.post(API_WINKELS_URL + 'add-shop', postData, { responseType: 'text' });
      }
      getWinkel(id): Observable<any> {
        return this.http.get(API_WINKELS_URL + 'get-shop?id='+id, { responseType: 'text' });
        }
        deleteWinkel(id): Observable<any> {
          return this.http.get(API_WINKELS_URL + 'remove-shop?id='+id, { responseType: 'text' });
          }

}
