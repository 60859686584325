Openstaande producten. Geef aan of deze binnen zijn of niet
<form [formGroup]="form" (ngSubmit)="submit()">

<cdk-accordion class="example-accordion">
  <cdk-accordion-item
    *ngFor="let item of shop_arr; let index = index;"
    #accordionItem="cdkAccordionItem"
    class="example-accordion-item"
    role="button"
    tabindex="0"
    (opened)="onItemOpened(item)"
    [attr.id]="'accordion-header-' + index"
    [attr.aria-expanded]="accordionItem.expanded"
    [expanded]="item.name==shop_selected"
    [attr.aria-controls]="'accordion-body-' + index">
    
    <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
	    {{ item.name }} Aantal: ({{ item.items? item.items.length:0}}) 
        <span class="example-accordion-item-description">
        Klik hier om te {{ accordionItem.expanded ? 'sluiten' : 'openen' }}
      </span>
    </div>
    <div
      class="example-accordion-item-body"
      role="region"
      [style.display]="accordionItem.expanded ? '' : 'none'"
      [attr.id]="'accordion-body-' + index"
      [attr.aria-labelledby]="'accordion-header-' + index">
      <div *ngFor="let product of item.items">
	      <div style="border:0px;width:25px;float:left;padding:2px"><input type=checkbox (change)="onCheckboxChange($event)" name="items[]" value="{{product.id}}" ></div>
	      <div style="border:0px;width:275px;float:left;padding:2px" (click)="openDialog(product.id,product.product)">{{product.product}} </div>
        <div style="border:0px solid #000;width:45px;float:left;padding:2px"><button (click)="removeItem(product.id)" class="btn btn-danger">Verwijder</button></div>
        <div style="clear:both"></div>
      </div>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
 <button style="margin-top:25px" mat-raised-button color="primary" type="submit">Sla op</button>
</form>
